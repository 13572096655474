import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stateSelector, actions } from './../Redux/Slices/ToastsSlice';

export type Toast = {
    type: ToastType;
    content: Array<string>;
    errorId?: string;
};

export type ToastWithId = Toast & {
    id: string;
};

export type ToastType = 'Success' | 'Warning' | 'Error';

const useToasts = (): { toasts: Array<ToastWithId>; addToast: (toast: Toast) => void; removeToast: (id: string) => void } => {
    const toasts = useSelector(stateSelector);
    const dispatch = useDispatch();
    const { addToast: addToastAction, removeToast: removeToastAction } = actions;
    const addToast = useCallback((toast: Toast) => dispatch(addToastAction(toast)), [dispatch, addToastAction]);
    const removeToast = useCallback((id: string) => dispatch(removeToastAction(id)), [dispatch, removeToastAction]);

    return { toasts, addToast, removeToast };
};

export default useToasts;
