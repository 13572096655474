import { useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { NewAnnotation, Categories } from 'Views/Types';
import { AddAnnotationBody } from 'Api/InternalWs.ApiClient';
import { SaveButton, SelectOption, ToolbarSelect } from '../NewAnnotationToolbar.Component';

export type BottomUpComponentProps = {
    webViewerInstance: WebViewerInstance;
    getNewAnnotation: () => Promise<NewAnnotation>;
    addAnnotation: (addAnnotationBody: AddAnnotationBody) => void;
    categories: Categories;
};

const BottomUpComponent = (props: BottomUpComponentProps) => {
    const { webViewerInstance, getNewAnnotation, addAnnotation, categories } = props;

    const [category, setCategory] = useState<SelectOption | null>(null);

    return (
        <>
            <ToolbarSelect
                value={category}
                onChange={(selected) => {
                    if (selected?.label !== category?.label) {
                        setCategory(selected);
                    }
                }}
                options={categories.map((c, idx) => ({ value: idx, label: c }))}
                placeholder={'Select category...'}
            />
            <SaveButton
                onClick={async () => {
                    const _id = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0]?.Id;
                    const _category = category?.label || '';
                    if (_id) {
                        const annotMetaData = await getNewAnnotation();
                        addAnnotation({
                            labelGroup: _category,
                            labelSubGroup: '',
                            boundingBoxSections: annotMetaData.boundingBoxSections,
                            selectionType: 0,
                        });
                    }
                }}
            />
        </>
    );
};

export default BottomUpComponent;
