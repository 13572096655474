import { Paper } from '@mui/material';
import './PdfViewer.Styles.scss';
import { useEffect, useRef, useState } from 'react';
import useWebViewerInstance from './hooks/useWebViewerInstance';
import { WebViewerInstance } from '@pdftron/webviewer';
import useInit from './hooks/useInit';
import createCache from '@emotion/cache';
import useToolbars from './hooks/useToolbars';
import useDrawing from './hooks/useDrawing';
import { AddAnnotationBody, Annotation, GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';

type PdfViewerComponentProps = {
    webViewerInstance: WebViewerInstance | null;
    setWebViewerInstance: (webViewerInstance: WebViewerInstance) => void;
    documentLoading: boolean;
    annotations: Array<Annotation>;
    selectedAnnotationId: string | null;
    setSelectedAnnotationId: (id: string | null) => void;
    addAnnotation: (addAnnotationBody: AddAnnotationBody) => void;
    removeAnnotation: (annotationId: string) => void;
    approach: GeneratingAutoAnnotationsApproach | null;
};

const PdfViewerComponent = (props: PdfViewerComponentProps) => {
    const {
        webViewerInstance,
        setWebViewerInstance,
        documentLoading,
        annotations,
        selectedAnnotationId,
        setSelectedAnnotationId,
        addAnnotation,
        removeAnnotation,
        approach,
    } = props;

    const [extendAnnotation, setExtendAnnotation] = useState<boolean>(false);
    const extendAnnotationRef = useRef<boolean>(extendAnnotation);
    extendAnnotationRef.current = extendAnnotation;

    const viewer = useRef<HTMLDivElement>(null);
    useWebViewerInstance(webViewerInstance, setWebViewerInstance, viewer);
    useInit(webViewerInstance, setSelectedAnnotationId, extendAnnotationRef, () => setExtendAnnotation(false));

    const cache = createCache({
        key: 'pdfviewer',
        container: webViewerInstance?.UI.iframeWindow.document.head,
        prepend: true,
    });

    useToolbars(webViewerInstance, cache, selectedAnnotationId, addAnnotation, removeAnnotation, () => setExtendAnnotation(true), approach);
    useDrawing(webViewerInstance, annotations, documentLoading, approach);

    useEffect(() => {
        extendAnnotationRef.current = extendAnnotation;
        if (extendAnnotation && webViewerInstance) webViewerInstance.Core.annotationManager.deselectAllAnnotations();
    }, [webViewerInstance, extendAnnotation]);

    return (
        <Paper sx={{ width: 'calc(100% - 20rem - 3rem)', marginLeft: '2rem', marginRight: '1rem', height: '100%' }}>
            <div style={{ height: '100%' }} ref={viewer}></div>
        </Paper>
    );
};

export default PdfViewerComponent;
