import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Provider } from 'react-redux';
import Store from 'Redux/Store';

const MsalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_AAD_CLIENT_ID as string,
        authority: process.env.REACT_APP_AUTH_AAD_AUTHORITY as string, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: window && window.location && window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

const MsalInstance = new PublicClientApplication(MsalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <Provider store={Store}>
            <MsalProvider instance={MsalInstance}>
                <App />
            </MsalProvider>
        </Provider>
    </BrowserRouter>
);
