import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { useEffect } from 'react';

const useWebViewerInstance = (
    webViewerInstance: WebViewerInstance | null,
    setWebViewerInstance: (webViewerInstance: WebViewerInstance) => void,
    viewer: React.RefObject<HTMLDivElement>
) => {
    useEffect(() => {
        if (!webViewerInstance) {
            WebViewer(
                {
                    path: '/webviewer',
                    disabledElements: [
                        'selectToolButton',
                        'menuButton',
                        'leftPanelButton',
                        'toolbarGroup-Shapes',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Edit',
                        'toolbarGroup-FillAndSign',
                        'toolbarGroup-Forms',
                        'toggleCompareModeButton',
                        'coverLayoutButton',
                        'toggleNotesButton',
                        'textPopup',
                    ],
                    fullAPI: true,
                    licenseKey: 'Insert commercial license key here after purchase',
                },
                viewer.current!
            ).then((instance) => {
                const { Core, UI } = instance;
                const { documentViewer, Annotations } = Core;

                UI.disableFeatures([UI.Feature.MultiViewerMode]);
                UI.setHeaderItems((header) => {
                    header.getHeader('toolbarGroup-Annotate').update([
                        {
                            type: 'toolGroupButton',
                            toolGroup: 'highlightTools',
                            dataElement: 'highlightToolGroupButton',
                            title: 'annotation.highlight',
                        },
                    ]);
                });

                const parentTags = Array.from(document.head.children).filter(
                    (tag) =>
                        (tag.nodeName === 'STYLE' || (tag.nodeName === 'LINK' && tag.getAttribute('rel') === 'stylesheet')) &&
                        tag.getAttribute('data-emotion') === null
                );
                parentTags.forEach((t) => {
                    const nodeNew = t.cloneNode(true);
                    UI.iframeWindow.document.head.appendChild(nodeNew);
                });

                documentViewer.addEventListener('documentLoaded', async () => {
                    UI.setFitMode(UI.FitMode.FitWidth);
                });
                Annotations.SelectionModel.defaultSelectionOutlineColor = new Annotations.Color(0, 0, 0, 0);

                UI.setToolbarGroup('toolbarGroup-View');
                UI.hotkeys.off();

                setWebViewerInstance(instance);
            });
        }
    }, [viewer, webViewerInstance, setWebViewerInstance]);

    return { webViewerInstance };
};

export default useWebViewerInstance;
