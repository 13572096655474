import useToasts from 'Hooks/useToasts';
import ToastComponent from './Toast/Toast.Component';
import './Toasts.Styles.scss';

const ToastsComponent = () => {
    const { toasts, removeToast } = useToasts();

    return (
        <div className='toasts'>
            {toasts.map((t) => (
                <div key={`toast-${t.id}`} className='toasts-item'>
                    <ToastComponent {...t} removeToast={() => removeToast(t.id)} />
                </div>
            ))}
        </div>
    );
};

export default ToastsComponent;
