import { useLayoutEffect, useState } from 'react';
import yaml from 'js-yaml';
import { Groups } from 'Views/Types';
import { GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';

const useTopDown = (approach: GeneratingAutoAnnotationsApproach | null) => {
    const [groups, setGroups] = useState<Groups>({});
    useLayoutEffect(() => {
        if (approach === GeneratingAutoAnnotationsApproach.TopDown) {
            const getGroups = async () => {
                const fileResponse = await fetch('/Groups.yml');
                const fileContent = await fileResponse.text();
                const groups = yaml.load(fileContent);
                setGroups(groups as Groups);
            };
            getGroups();
        }
    }, [setGroups, approach]);

    return { groups };
};

export default useTopDown;
