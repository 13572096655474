import { Route, Routes } from 'react-router-dom';
import HomePageComponent from '../Views/HomePage/HomePage.Component';
import DocumentWorkspaceComponent from '../Views/DocumentWorkspace/DocumentWorkspace.Component';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import { useMemo } from 'react';
import BaseApiClient from 'Api/Base.ApiClient';
import InternalWsApiClient from 'Api/InternalWs.ApiClient';
import ToastsComponent from './Toasts/Toasts.Component';
import useToasts from 'Hooks/useToasts';
import DocumentsListComponent from 'Views/DocumentsList/DocumentsList.Component';

const App = () => {
    const { addToast } = useToasts();
    const { instance } = useMsal();
    const baseApiClient = useMemo(() => new BaseApiClient(instance, addToast), [instance, addToast]);
    const internalWsApiClient = useMemo(() => new InternalWsApiClient(baseApiClient), [baseApiClient]);

    return (
        <div style={{ height: '100vh' }}>
            <ToastsComponent />
            <AuthenticatedTemplate>
                <Routes>
                    <Route path={'/'} element={<HomePageComponent />} />
                    <Route path={'/documentsList/:approach'} element={<DocumentsListComponent internalWsApiClient={internalWsApiClient} />} />
                    <Route path={'/documentWorkspace/:docId'} element={<DocumentWorkspaceComponent internalWsApiClient={internalWsApiClient} />} />
                </Routes>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginComponent />
            </UnauthenticatedTemplate>
        </div>
    );
};

const LoginComponent = () => {
    const { instance } = useMsal();
    return (
        <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant='contained' onClick={() => instance.loginRedirect()}>
                Login by Microsoft account
            </Button>
        </div>
    );
};

export default App;
