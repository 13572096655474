import './DocumentWorkspace.Styles.scss';
import { Box, Button, CircularProgress, Link, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import PdfViewerComponent from './PdfViewer/PdfViewer.Component';
import AnnotationsListComponent from './AnnotationsList/AnnotationsList.Component';
import { WebViewerInstance } from '@pdftron/webviewer';
import { useCallback, useLayoutEffect, useState } from 'react';
import { getAnnotationId } from './PdfViewer/hooks/Attributes';
import InternalWsApiClient, { AddAnnotationBody, Annotation, GeneratingAutoAnnotationsApproach, VerifiDocument } from 'Api/InternalWs.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { ApproachesDictionary } from 'Views/Consts';

type DocumentWorkspaceComponentProps = {
    internalWsApiClient: InternalWsApiClient;
};

const DocumentWorkspaceComponent = (props: DocumentWorkspaceComponentProps) => {
    const { docId: _docId } = useParams();
    const docId = _docId!;
    const { internalWsApiClient } = props;
    const [webViewerInstance, setWebViewerInstance] = useState<WebViewerInstance | null>(null);
    const [documentLoading, setDocumentLoading] = useState<boolean>(false);

    const { fetching: fetchingDocInfo, data: document } = usePromiseWithFlowMethods<{ documentId: string }, VerifiDocument | null>({
        method: (input, flowMethods) => internalWsApiClient.getDocument(input.documentId, flowMethods),
        initialData: null,
        initFetch: { input: { documentId: docId } },
    });

    useLayoutEffect(() => {
        if (webViewerInstance) {
            webViewerInstance.Core.documentViewer.closeDocument();
            setDocumentLoading(true);
            webViewerInstance.UI.openElements(['loadingModal']);
            internalWsApiClient.getDocumentFile(docId).then((fileInfo) => {
                if (fileInfo) {
                    webViewerInstance.Core.documentViewer.loadDocument(fileInfo.file).then(() => {
                        webViewerInstance.UI.closeElements(['loadingModal']);
                        setDocumentLoading(false);
                    });
                }
            });
        }
    }, [webViewerInstance, internalWsApiClient, docId]);

    const [selectedAnnotationId, setSelectedAnnotationId] = useState<string | null>(null);

    const {
        data: annotations,
        wrappedMethod: _refetchAnnotations,
        fetching: fetchingAnnotations,
    } = usePromiseWithFlowMethods<{ docId: string }, Array<Annotation>>({
        method: (input, flowMethods) => internalWsApiClient.getAnnotations(input.docId, flowMethods),
        initialData: [],
        initFetch: { input: { docId } },
    });
    const getAnnotations = useCallback(() => _refetchAnnotations({ docId }), [_refetchAnnotations, docId]);

    const { wrappedMethod: _addAnnotation, fetching: adding } = usePromiseWithFlowMethods<{ docId: string; addAnnotationBody: AddAnnotationBody }, {}>({
        method: (input, flowMethods) =>
            internalWsApiClient.addAnnotation(input.docId, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess && flowMethods.onSuccess(data);
                    getAnnotations();
                },
                body: input.addAnnotationBody,
            }),
        initialData: {},
    });
    const addAnnotation = (addAnnotationBody: AddAnnotationBody) => _addAnnotation({ docId, addAnnotationBody });

    const { wrappedMethod: _removeAnnotation, fetching: removing } = usePromiseWithFlowMethods<{ docId: string; annotationId: string }, {}>({
        method: (input, flowMethods) =>
            internalWsApiClient.deleteAnnotation(input.docId, input.annotationId, {
                ...flowMethods,
                onSuccess: (data) => {
                    flowMethods.onSuccess && flowMethods.onSuccess(data);
                    getAnnotations();
                },
            }),
        initialData: {},
    });
    const removeAnnotation = (annotationId: string) => _removeAnnotation({ docId, annotationId });

    const [fileSaving, setFileSaving] = useState<boolean>(false);

    const processing = fetchingAnnotations || fetchingDocInfo || adding || removing || fileSaving || documentLoading;
    return (
        <div className='dw'>
            <div className='dw-head'>
                <Paper sx={{ height: '3.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='h5' component='h5' sx={{ marginLeft: '1rem' }}>
                        Document:{' '}
                        <Box component='span' fontWeight='bold'>
                            {document?.name || 'Loading...'}
                        </Box>
                    </Typography>
                    <Typography variant='h6' component='h6' sx={{ marginLeft: '1rem' }}>
                        {document && `(Approach: ${ApproachesDictionary[document.approach].name})`}
                    </Typography>
                    {fileSaving ? (
                        <CircularProgress sx={{ marginLeft: '2rem' }} size={30} />
                    ) : (
                        <Button
                            sx={{ height: '2rem', marginLeft: '2rem' }}
                            variant='contained'
                            onClick={() => exportXfdf(webViewerInstance, internalWsApiClient, docId, setFileSaving, document?.approach || null)}
                            disabled={processing || annotations.length === 0}
                        >
                            Save XFDF
                        </Button>
                    )}
                    <Link href={`/documentsList/${document?.approach}`} underline='none' sx={{ marginLeft: 'auto', marginRight: '1rem' }}>
                        Back to documents list
                    </Link>
                </Paper>
            </div>
            <div className='dw-workspace'>
                {document && (
                    <AnnotationsListComponent
                        annotations={annotations}
                        fetching={fetchingAnnotations}
                        selectedAnnotationId={selectedAnnotationId}
                        webViewerInstance={webViewerInstance}
                        approach={document.approach}
                    />
                )}
                <PdfViewerComponent
                    webViewerInstance={webViewerInstance}
                    setWebViewerInstance={setWebViewerInstance}
                    documentLoading={documentLoading}
                    annotations={annotations}
                    selectedAnnotationId={selectedAnnotationId}
                    setSelectedAnnotationId={setSelectedAnnotationId}
                    addAnnotation={addAnnotation}
                    removeAnnotation={removeAnnotation}
                    approach={document?.approach || null}
                />
            </div>
        </div>
    );
};

const exportXfdf = (
    webViewerInstance: WebViewerInstance | null,
    internalWsApiClient: InternalWsApiClient,
    docId: string,
    setFileSaving: (fetching: boolean) => void,
    approach: GeneratingAutoAnnotationsApproach | null
) => {
    if (webViewerInstance && approach) {
        const annotationManager = webViewerInstance.Core.annotationManager;
        const annots = annotationManager.getAnnotationsList().filter((a) => getAnnotationId(a) !== null);
        annotationManager.exportAnnotations({ annotList: annots }).then((annotData) => {
            // Remove 'trn-custom-data' tag
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(annotData, 'text/xml');
            const tag = xmlDoc.querySelectorAll('trn-custom-data');
            let f = Array.from(tag);
            f.forEach((x) => x.remove());
            // Save to file
            const serializer = new XMLSerializer();
            const newXml = serializer.serializeToString(xmlDoc);
            const blob = new Blob([newXml], { type: 'application/xml' });
            const fileName = getXfdfFileName(webViewerInstance.Core.documentViewer.getDocument().getFilename(), approach);
            const file = new File([blob], fileName);
            internalWsApiClient.saveXfdfFile(docId, {
                body: {
                    name: fileName,
                    document: file,
                },
                setFetching: setFileSaving,
            });
        });
    }
};

const getXfdfFileName = (docName: string, approach: GeneratingAutoAnnotationsApproach) => {
    let suffix: string | null = null;
    if (approach === GeneratingAutoAnnotationsApproach.BottomUp) {
        suffix = '[Bottom-Up]';
    }
    const fileName = `${docName}${suffix ? ` - ${suffix}` : ''}.xfdf`;
    return fileName;
};

export default DocumentWorkspaceComponent;
