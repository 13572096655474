import { Core } from '@pdftron/webviewer';

type AnnotationAttribute<TValues> = {
    get: (a: Core.Annotations.Annotation) => TValues;
    set: (a: Core.Annotations.Annotation, value: TValues) => void;
};
type AnnotationAttributeBooleanValues = 'true' | 'false';

export const AnnotationAttributeIsNewAnnotation: AnnotationAttribute<AnnotationAttributeBooleanValues> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeIsNewAnnotation') as AnnotationAttributeBooleanValues,
    set: (a: Core.Annotations.Annotation, value: AnnotationAttributeBooleanValues) => a.setCustomData('AnnotationAttributeIsNewAnnotation', value),
};
export const isNewAnnotation = (a: Core.Annotations.Annotation) => {
    return AnnotationAttributeIsNewAnnotation.get(a) === 'true';
};

export const AnnotationAttributeAnnotationId: AnnotationAttribute<string> = {
    get: (a: Core.Annotations.Annotation) => a.getCustomData('AnnotationAttributeAnnotationId'),
    set: (a: Core.Annotations.Annotation, value: string) => a.setCustomData('AnnotationAttributeAnnotationId', value),
};
export const getAnnotationId = (a: Core.Annotations.Annotation) => AnnotationAttributeAnnotationId.get(a);
