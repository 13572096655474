import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import InternalWsApiClient, { GeneratingAutoAnnotationsApproach, VerifiDocument } from 'Api/InternalWs.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { ApproachesDictionary } from 'Views/Consts';

type DocumentsListComponentProps = {
    internalWsApiClient: InternalWsApiClient;
};

const DocumentsListComponent = (props: DocumentsListComponentProps) => {
    const { internalWsApiClient } = props;
    const { approach: _approach } = useParams();
    const approach = _approach! as any as GeneratingAutoAnnotationsApproach;
    const navigate = useNavigate();

    const { fetching, data: documents } = usePromiseWithFlowMethods<{}, Array<VerifiDocument>>({
        method: (_input, flowMethods) => internalWsApiClient.getDocuments(approach, flowMethods),
        initialData: [],
        initFetch: { input: {} },
    });

    return (
        <Box sx={{ padding: '3rem', height: 'calc(100% - 3rem - 3rem)' }} className='home-page'>
            <Paper sx={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
                <Typography variant='h3' component='h3'>
                    Documents List
                </Typography>
                <Typography variant='h5' component='h5' sx={{ marginLeft: '2rem' }}>
                    {`(Approach: ${ApproachesDictionary[approach].name})`}
                </Typography>
                {fetching && <CircularProgress sx={{ marginLeft: '2rem' }} />}
                <Link href={`/`} underline='none' sx={{ marginLeft: 'auto', marginRight: '1rem' }}>
                    Back to Home Page
                </Link>
            </Paper>
            <Box sx={{ marginTop: '2rem', paddingBottom: '4rem' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '40rem' }} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Annotations</TableCell>
                                <TableCell>All changes saved</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((doc) => (
                                    <TableRow
                                        key={doc.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                        onClick={() => navigate(`/documentWorkspace/${doc.id}`)}
                                    >
                                        <TableCell>{doc.name}</TableCell>
                                        <TableCell>{doc.annotationsCount}</TableCell>
                                        <TableCell sx={{ color: doc.isDone ? 'green' : 'red', fontWeight: 'bold' }}>{doc.isDone ? 'Yes' : 'No'}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default DocumentsListComponent;
