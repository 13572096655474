import { Box, Button, Typography } from '@mui/material';
import { GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';
import { ApproachesDictionary } from 'Views/Consts';
import { useNavigate } from 'react-router-dom';

const HomePageComponent = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box>
                <Typography variant='h3' component='h3' sx={{ padding: '1rem', textAlign: 'center' }}>
                    Choose a approach:
                </Typography>
                <Box sx={{ display: 'grid', rowGap: 4 }}>
                    {Object.keys(ApproachesDictionary).map((key) => (
                        <Button
                            sx={{ width: '50rem', fontSize: '2.5rem', fontWeight: 600 }}
                            variant='outlined'
                            onClick={() => navigate(`/documentsList/${key}`)}
                        >
                            {ApproachesDictionary[key as any as GeneratingAutoAnnotationsApproach].name}
                        </Button>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default HomePageComponent;
