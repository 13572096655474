import { IPublicClientApplication } from '@azure/msal-browser';
import { Toast } from 'Hooks/useToasts';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';

export type GetRequestOptions<TData> = FlowMethods<TData> & RequestOptions;

export type PostRequestOptions<TBody = {}, TData = {}> = PutPostRequestOptions<TBody, TData>;

export type PutRequestOptions<TBody = {}, TData = {}> = PutPostRequestOptions<TBody, TData>;

export type PutPostRequestOptions<TBody = {}, TData = {}> = FlowMethods<TData> &
    RequestOptions & {
        body?: TBody;
        bodyAsDataForm?: boolean;
    };

export type DeleteRequestOptions<TData = {}> = FlowMethods<TData> & RequestOptions;

export type RequestOptions = {
    toastSuccessMessages?: Array<string>;
};

export type FlowMethods<TData = {}> = {
    onInit?: () => void;
    onSuccess?: (data: TData) => void;
    onError?: () => void;
    setFetching?: (isFetching: boolean) => void;
};

type ErrorResponseData = {
    errorId: string;
    message: string;
    displayMessages: Array<string>;
};

export default class BaseApiClient {
    _apiToken: string | null;
    _apiUrl: string;
    _publicClientApplication: IPublicClientApplication;
    _scopes: Array<string>;
    _addToast: (toast: Toast) => void;

    constructor(publicClientApplication: IPublicClientApplication, addToast: (toast: Toast) => void) {
        this._apiToken = null;
        this._apiUrl = process.env.REACT_APP_INTERNAL_WS_URL;
        this._publicClientApplication = publicClientApplication;
        this._scopes = process.env.REACT_APP_AUTH_AAD_SCOPES.split(', ');
        this._addToast = addToast;
    }

    getAsync = async <TData,>(url: string, requestOptions?: GetRequestOptions<TData>) => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            const result = await axios<TData>({
                method: 'get',
                url: `${this._apiUrl}${url}`,
                headers,
            }).then(
                (response) => {
                    requestOptions?.onSuccess && requestOptions?.onSuccess(response.data);
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    return response.data;
                },
                (error) => {
                    requestOptions?.onError && requestOptions?.onError();
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    this._errorHandle(error);
                    return null as TData;
                }
            );
            return result;
        } catch (error) {
            throw error;
        }
    };

    getFileAsync = async (url: string, fileId: string, requestOptions?: GetRequestOptions<File>) => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            const result = await axios<Blob>({
                method: 'get',
                url: `${this._apiUrl}${url}`,
                headers,
                responseType: 'blob',
            }).then(
                (response) => {
                    const contentDisposition = response.headers['content-disposition'];
                    let fileName = 'Empty file name';

                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(/filename=(.+);/);
                        if (fileNameMatch.length === 2) {
                            fileName = fileNameMatch[1].replaceAll(`"`, '');
                        }
                    }
                    const file = new File([response.data], fileName);
                    requestOptions?.onSuccess && requestOptions?.onSuccess(file);
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    return { id: fileId, file };
                },
                (error) => {
                    requestOptions?.onError && requestOptions?.onError();
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    this._errorHandle(error);
                    return null;
                }
            );
            return result;
        } catch (error) {
            throw error;
        }
    };

    postAsync = async <TBody = {}, TData = {}>(url: string, requestOptions?: PostRequestOptions<TBody, TData>): Promise<any> =>
        this._putPostAsync(url, 'post', requestOptions);

    putAsync = async <TBody = {}, TData = {}>(url: string, requestOptions?: PostRequestOptions<TBody, TData>): Promise<any> =>
        this._putPostAsync(url, 'put', requestOptions);

    _putPostAsync = async <TBody = {}, TData = {}>(url: string, method: 'put' | 'post', requestOptions?: PutPostRequestOptions<TBody, TData>): Promise<any> => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            await axios<TData>({
                method: method,
                url: `${this._apiUrl}${url}`,
                headers: { ...headers, ...(requestOptions?.bodyAsDataForm ? { 'Content-Type': 'multipart/form-data' } : {}) },
                data: requestOptions?.body,
            }).then(
                (response) => {
                    requestOptions?.toastSuccessMessages && this._addToast({ type: 'Success', content: requestOptions.toastSuccessMessages });
                    requestOptions?.onSuccess && requestOptions.onSuccess(response.data);
                    requestOptions?.setFetching && requestOptions.setFetching(false);
                },
                (error) => {
                    requestOptions?.onError && requestOptions.onError();
                    requestOptions?.setFetching && requestOptions.setFetching(false);
                    this._errorHandle(error);
                }
            );
        } catch (error) {
            throw error;
        }
    };

    deleteAsync = async <TData = {},>(url: string, requestOptions?: DeleteRequestOptions<TData>): Promise<any> => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            await axios<TData>({
                method: 'delete',
                url: `${this._apiUrl}${url}`,
                headers,
            }).then(
                (response) => {
                    requestOptions?.toastSuccessMessages && this._addToast({ type: 'Success', content: requestOptions.toastSuccessMessages });
                    requestOptions?.onSuccess && requestOptions?.onSuccess(response.data);
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                },
                (error) => {
                    requestOptions?.onError && requestOptions?.onError();
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    this._errorHandle(error);
                }
            );
        } catch (error) {
            throw error;
        }
    };

    _getAuthenticationHeaderAsync = async () => {
        const token = await this._getToken();

        const headers: RawAxiosRequestHeaders = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        return headers;
    };

    _getToken = async () => {
        let token = this._apiToken;

        if (!this._checkTokenExpirationDate(token)) {
            token = await this._refreshToken();
        }

        return token;
    };

    _checkTokenExpirationDate = (token: string | null) => {
        if (token === null) return false;

        const decodedToken = this._decodeToken(token);

        return new Date(decodedToken.exp * 1000) > new Date();
    };

    _decodeToken = (token: string) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    };

    _refreshToken = async (): Promise<string> => {
        let token: string = '';

        const account = this._publicClientApplication.getAllAccounts()[0];
        const accessTokenRequest = {
            scopes: this._scopes,
            account: account,
        };

        await this._publicClientApplication
            .acquireTokenSilent(accessTokenRequest)
            .then((response) => {
                token = response.accessToken;
            })
            .catch((error) => {
                console.log(`_refreshToken -> ${error}`);
            });

        return token;
    };

    _errorHandle = (error: AxiosError<ErrorResponseData>) => {
        /*
        if (error.response) {
            const response = error.response;
            this._addToast({
                type: response.status >= 500 || [404, 405].includes(response.status) ? 'Error' : 'Warning',
                content: response.data.displayMessages || ['Undefined message.'],
                errorId: response.data.errorId || 'Undefined Error Id.',
            });
        } else*/
        this._addToast({ type: 'Error', content: [error.message], errorId: error.code });
    };
}
