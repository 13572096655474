import { GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';
import { AnnotationColor } from './Types';

export const AnnotationColors: { selected: AnnotationColor; default: AnnotationColor; new: AnnotationColor } = {
    selected: { R: 79, G: 117, B: 255, A: 0.4 },
    default: { R: 255, G: 228, B: 155, A: 0.4 },
    new: { R: 193, G: 104, B: 0, A: 0.2 },
};

export const ApproachesDictionary: { [key in GeneratingAutoAnnotationsApproach]: { name: string } } = {
    1: { name: 'Top-Down' },
    2: { name: 'Bottom-Up' },
};
