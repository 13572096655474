import { useEffect, useRef } from 'react';
import { Button, Tooltip } from '@mui/material';
import Select from 'react-select';
import { WebViewerInstance } from '@pdftron/webviewer';
import { ReactComponent as Plus } from './Plus.svg';
import { NewAnnotation, Groups, Categories } from 'Views/Types';
import { AddAnnotationBody, GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';
import TopDownComponent from './Approaches/TopDown.Component';
import BottomUpComponent from './Approaches/BottomUp.Component';

export type NewAnnotationToolbarComponentProps = {
    webViewerInstance: WebViewerInstance;
    setAnnotationToolbarRef: (ref: React.RefObject<HTMLDivElement>) => void;
    getNewAnnotation: () => Promise<NewAnnotation>;
    addAnnotation: (addAnnotationBody: AddAnnotationBody) => void;
    setExtendAnnotation: () => void;
    groups: Groups;
    categories: Categories;
    approach: GeneratingAutoAnnotationsApproach;
};

export type SelectOption = {
    value: number;
    label: string;
};

const NewAnnotationToolbarComponent = (props: NewAnnotationToolbarComponentProps) => {
    const { webViewerInstance, setAnnotationToolbarRef, getNewAnnotation, addAnnotation, setExtendAnnotation, groups, categories, approach } = props;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setAnnotationToolbarRef(ref);
    }, [setAnnotationToolbarRef]);

    let panel: JSX.Element | null = null;
    switch (approach) {
        case GeneratingAutoAnnotationsApproach.TopDown:
            panel = (
                <TopDownComponent webViewerInstance={webViewerInstance} getNewAnnotation={getNewAnnotation} addAnnotation={addAnnotation} groups={groups} />
            );
            break;
        case GeneratingAutoAnnotationsApproach.BottomUp:
            panel = (
                <BottomUpComponent
                    webViewerInstance={webViewerInstance}
                    getNewAnnotation={getNewAnnotation}
                    addAnnotation={addAnnotation}
                    categories={categories}
                />
            );
            break;
    }

    return (
        <div ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
            {panel}
            <Tooltip title='Add next part of text'>
                <Button sx={{ height: '2rem', padding: '0.25rem', minWidth: '2rem' }} variant='contained' onClick={() => setExtendAnnotation()}>
                    <Plus style={{ fill: '#fff' }} />
                </Button>
            </Tooltip>
        </div>
    );
};

type ToolbarSelectProps = {
    value: SelectOption | null;
    onChange: (selected: SelectOption | null) => void;
    options: Array<SelectOption>;
    placeholder: string;
    isDisabled?: boolean;
};

export const ToolbarSelect = (props: ToolbarSelectProps) => {
    const { value, onChange, options, placeholder, isDisabled } = props;
    return (
        <Select<SelectOption, false>
            defaultValue={null}
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            isDisabled={isDisabled}
            styles={{
                menu: (base, _props) => ({
                    ...base,
                    width: '20rem',
                }),
                input: (base, _props) => ({
                    ...base,
                    width: '7rem',
                }),
                control: (base, _props) => ({
                    ...base,
                    marginRight: '0.5rem',
                }),
            }}
        />
    );
};

type SaveButtonProps = {
    onClick: () => void;
};

export const SaveButton = (props: SaveButtonProps) => {
    const { onClick } = props;
    return (
        <Button sx={{ height: '2rem', marginRight: '0.5rem' }} variant='contained' onClick={onClick}>
            Save
        </Button>
    );
};

export default NewAnnotationToolbarComponent;
