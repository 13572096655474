import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useEffect } from 'react';
import { AnnotationAttributeIsNewAnnotation, getAnnotationId, isNewAnnotation } from './Attributes';
import { AnnotationColors } from 'Views/Consts';
import { HighlightColorToAnnotationsColor } from './useDrawing';

const useInit = (
    webViewerInstance: WebViewerInstance | null,
    setSelectedAnnotationId: (id: string | null) => void,
    extendAnnotationRef: React.MutableRefObject<boolean>,
    setExtendAnnotation: () => void
) => {
    useEffect(() => {
        if (webViewerInstance) {
            const defaultColor = HighlightColorToAnnotationsColor(webViewerInstance.Core.Annotations, AnnotationColors.new);
            webViewerInstance.Core.documentViewer
                .getTool(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT)
                .setStyles({ FillColor: defaultColor, StrokeColor: defaultColor });
            webViewerInstance.Core.annotationManager.setCurrentUser('Labelling App');
            RegisterAnnotationAddedListener(webViewerInstance, extendAnnotationRef, setExtendAnnotation);
            RegisterAnnotationSelectedListener(webViewerInstance, setSelectedAnnotationId, extendAnnotationRef);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, setSelectedAnnotationId]);
};

const RegisterAnnotationAddedListener = (
    webViewerInstance: WebViewerInstance,
    extendAnnotationRef: React.MutableRefObject<boolean>,
    setExtendAnnotation: () => void
) => {
    const { documentViewer, annotationManager } = webViewerInstance.Core;

    const onAnnotationAdded = (annot: Core.Annotations.TextHighlightAnnotation | Core.Annotations.RectangleAnnotation) => {
        AnnotationAttributeIsNewAnnotation.set(annot, 'true');
        annotationManager.selectAnnotation(annot);
        if (extendAnnotationRef.current) setExtendAnnotation();
    };

    const textHightlightTool = documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT);
    textHightlightTool.addEventListener('annotationAdded', (annot: Core.Annotations.TextHighlightAnnotation) => onAnnotationAdded(annot));
};

const RegisterAnnotationSelectedListener = (
    webViewerInstance: WebViewerInstance,
    setSelectedAnnotationId: (id: string | null) => void,
    extendAnnotationRef: React.MutableRefObject<boolean>
) => {
    const annotationManager = webViewerInstance.Core.annotationManager;
    annotationManager.addEventListener('annotationSelected', (annotations: Array<Core.Annotations.Annotation>, action: string) => {
        const allAnnotations = annotationManager.getAnnotationsList();
        const selectedAnnotations = annotationManager.getSelectedAnnotations();
        if (selectedAnnotations.length > 1) {
            const annot = selectedAnnotations.reverse()[0];
            const index = selectedAnnotations.indexOf(annot);
            const annotationsToDeselect = [...selectedAnnotations.slice(0, index), ...selectedAnnotations.slice(index + 1)];
            annotationManager.deselectAnnotations(annotationsToDeselect);

            setTimeout(() => {
                // For Annotation Toolbar -> in another way it will be display under first annotation before deselect
                annotationManager.selectAnnotation(annot);
            }, 10);
        } else {
            if (selectedAnnotations.length === 1) {
                const annot = selectedAnnotations[0];
                const annotId = getAnnotationId(annot);
                setSelectedAnnotationId(annotId);
                const annots = allAnnotations.filter((a) => getAnnotationId(a) === annotId && !isNewAnnotation(a));
                annots.forEach(
                    (a) =>
                        ((a as Core.Annotations.TextHighlightAnnotation).StrokeColor = HighlightColorToAnnotationsColor(
                            webViewerInstance.Core.Annotations,
                            AnnotationColors.selected
                        ))
                );
                annotationManager.drawAnnotationsFromList(annots);
            } else setSelectedAnnotationId(null);
        }

        if (selectedAnnotations.length === 0 && !extendAnnotationRef.current) {
            const toRemove = allAnnotations.filter((a) => isNewAnnotation(a));
            annotationManager.deleteAnnotations(toRemove);
        }

        if (action === 'deselected') {
            annotations.forEach((annot) => {
                const annotId = getAnnotationId(annot);
                const annots = allAnnotations.filter((a) => getAnnotationId(a) === annotId && !isNewAnnotation(a));
                annots.forEach(
                    (a) =>
                        ((a as Core.Annotations.TextHighlightAnnotation).StrokeColor = HighlightColorToAnnotationsColor(
                            webViewerInstance.Core.Annotations,
                            AnnotationColors.default
                        ))
                );
                annotationManager.drawAnnotationsFromList(annots);
            });
        }
    });
};

export default useInit;
