import { useLayoutEffect, useState } from 'react';
import yaml from 'js-yaml';
import { Categories } from 'Views/Types';
import { GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';

const useBottomUp = (approach: GeneratingAutoAnnotationsApproach | null) => {
    const [categories, setCategories] = useState<Categories>([]);
    useLayoutEffect(() => {
        if (approach === GeneratingAutoAnnotationsApproach.BottomUp) {
            const getCategories = async () => {
                const fileResponse = await fetch('/Categories.yml');
                const fileContent = await fileResponse.text();
                const categories = yaml.load(fileContent);
                setCategories(categories as Categories);
            };
            getCategories();
        }
    }, [setCategories, approach]);

    return { categories };
};

export default useBottomUp;
