import { useEffect, useRef } from 'react';
import { Button, Paper } from '@mui/material';
import { WebViewerInstance } from '@pdftron/webviewer';
import { getAnnotationId } from '../../hooks/Attributes';

export type AnnotationToolbarComponentProps = {
    webViewerInstance: WebViewerInstance;
    setAnnotationToolbarRef: (ref: React.RefObject<HTMLDivElement>) => void;
    removeAnnotation: (id: string) => void;
};

const AnnotationToolbarComponent = (props: AnnotationToolbarComponentProps) => {
    const { webViewerInstance, setAnnotationToolbarRef, removeAnnotation } = props;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setAnnotationToolbarRef(ref);
    }, [setAnnotationToolbarRef]);

    return (
        <Paper ref={ref}>
            <Button
                variant='contained'
                onClick={() => {
                    const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
                    if (annot) {
                        const id = getAnnotationId(annot);
                        if (id) removeAnnotation(id);
                    }
                }}
            >
                remove
            </Button>
        </Paper>
    );
};

export default AnnotationToolbarComponent;
