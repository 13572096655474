import { useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { NewAnnotation, Groups } from 'Views/Types';
import { AddAnnotationBody } from 'Api/InternalWs.ApiClient';
import { SaveButton, SelectOption, ToolbarSelect } from '../NewAnnotationToolbar.Component';

export type TopDownComponentProps = {
    webViewerInstance: WebViewerInstance;
    getNewAnnotation: () => Promise<NewAnnotation>;
    addAnnotation: (addAnnotationBody: AddAnnotationBody) => void;
    groups: Groups;
};

const TopDownComponent = (props: TopDownComponentProps) => {
    const { webViewerInstance, getNewAnnotation, addAnnotation, groups } = props;

    const [group, setGroup] = useState<SelectOption | null>(null);
    const [subGroup, setSubGroup] = useState<SelectOption | null>(null);

    return (
        <>
            <ToolbarSelect
                value={group}
                onChange={(selected) => {
                    if (selected?.label !== group?.label) {
                        setSubGroup(null);
                        setGroup(selected);
                    }
                }}
                options={Object.keys(groups).map((g, idx) => ({ value: idx, label: g }))}
                placeholder={'Select group...'}
            />
            <ToolbarSelect
                value={subGroup}
                onChange={(selected) => setSubGroup(selected)}
                options={group?.label ? groups[group?.label].map((g, idx) => ({ value: idx, label: g })) : []}
                placeholder={group?.label ? 'Select sub-group...' : 'Select group first'}
                isDisabled={!group?.label}
            />
            <SaveButton
                onClick={async () => {
                    const _id = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0]?.Id;
                    const _group = group?.label;
                    const _subGroup = subGroup?.label;
                    if (_id && _group && _subGroup) {
                        const annotMetaData = await getNewAnnotation();
                        addAnnotation({
                            labelGroup: _group,
                            labelSubGroup: _subGroup,
                            boundingBoxSections: annotMetaData.boundingBoxSections,
                            selectionType: 0,
                        });
                    }
                }}
            />
        </>
    );
};

export default TopDownComponent;
