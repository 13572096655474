import classNames from 'classnames';
import './Toast.Styles.scss';
import { Toast } from 'Hooks/useToasts';
import { Check, Close, Icon, QuestionCircle, Warning } from 'CommonStyles/Icons';
import { useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';

export type ToastComponentProps = Toast & {
    removeToast: () => void;
};

const ToastComponent = (props: ToastComponentProps) => {
    const { type, content, errorId, removeToast } = props;
    const [extended, setExtended] = useState<boolean>();
    const autoClose: boolean = useMemo(() => type !== 'Error', [type]);

    const [progress, setProgress] = useState<number>(0);
    useEffect(() => {
        if (autoClose) {
            const interval: number = 50;
            const toastExpirationMs: number = 4000;
            const step: number = (interval / toastExpirationMs) * 100;
            const iterationsCount: number = toastExpirationMs / interval;
            const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
            const runTimer = async () => {
                for (let i = 0; i < iterationsCount; i++) await delay(interval).then(() => setProgress((prev) => prev + step));
            };
            runTimer();
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (progress === 100) setTimeout(() => removeToast(), 500);
        // eslint-disable-next-line
    }, [progress]);

    const closeAction = (
        <div className='toast-right-close-container'>
            <Close className={classNames('toast-right-action-icon', 'toast-right-close-container-icon')} />
            <CircularProgress
                size={20}
                sx={{
                    position: 'absolute',
                    color: '#fff',
                    zIndex: 1,
                    cursor: 'pointer',
                }}
                variant='determinate'
                value={progress}
                onClick={() => removeToast()}
            />
        </div>
    );

    const simpleCloseAction = (
        <div className='toast-right-close-container toast-right-close-container-extended'>
            <QuestionCircle className='toast-right-action-icon' onClick={() => setExtended((prevState) => !prevState)} />
            <Close className={classNames('toast-right-action-icon', 'toast-right-close-container-icon', 'toast-right-close-container-extended-icon')} onClick={() => removeToast()}/>
        </div>
    );

    let ToastIcon: Icon | null = null;
    let action: JSX.Element | null = null;
    switch (type) {
        case 'Success':
            ToastIcon = Check;
            action = closeAction;
            break;
        case 'Warning':
            ToastIcon = Warning;
            action = closeAction;
            break;
        case 'Error':
            ToastIcon = Close;
            action = simpleCloseAction;
            break;
    }

    return (
        <>
            <div className={classNames('toast', `toast-${type}`, extended && 'toast-extended')}>
                <div className='toast-left'>
                    <div className='toast-left-icon-container'>
                        <ToastIcon className={classNames('toast-left-icon-container-icon', `toast-left-icon-container-icon-${type}`)} />
                    </div>
                </div>
                <div className='toast-content'>
                    <div className='toast-content-type'>{type}</div>
                    <div className='toast-content-message'>
                        {content.map((c, idx) => (
                            <div key={`toast-content-${idx}`}>
                                <>{c}</>
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='toast-right'>{action}</div>
            </div>
            {extended && (
                <div className={classNames('toast-extension', `toast-extension-${type}`)}>
                    <div className='toast-extension-content'>
                        <div className='toast-extension-content-title'>Error code</div>
                        <div className='toast-extension-content-message'>{errorId}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ToastComponent;
