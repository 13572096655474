import { Box, CircularProgress, Divider, List, ListItem, Paper, Typography } from '@mui/material';
import { WebViewerInstance } from '@pdftron/webviewer';
import { getAnnotationId } from '../PdfViewer/hooks/Attributes';
import { Annotation, GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';

type AnnotationsListComponentProps = {
    annotations: Array<Annotation>;
    fetching: boolean;
    selectedAnnotationId: string | null;
    webViewerInstance: WebViewerInstance | null;
    approach: GeneratingAutoAnnotationsApproach;
};

const AnnotationsListComponent = (props: AnnotationsListComponentProps) => {
    const { annotations, fetching, selectedAnnotationId, webViewerInstance, approach } = props;

    return (
        <Paper sx={{ width: '20rem', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6' component='h6' sx={{ height: '3rem', marginLeft: '1rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                    Annotations - {annotations.length}
                </Typography>
                {fetching && <CircularProgress size={30} sx={{ marginLeft: '2rem' }} />}
            </div>
            <Divider />
            <List sx={{ width: '100%', bgcolor: 'background.paper', height: 'calc(100% - 3rem)', overflow: 'auto', paddingBottom: '0px', paddingTop: '0px' }}>
                {annotations.map((a, idx) => (
                    <div key={`a.id-${idx}`}>
                        <ListItem
                            sx={{ display: 'inline-block', cursor: 'pointer', backgroundColor: a.id === selectedAnnotationId ? '#4f75ff66' : 'initial' }}
                            onClick={() => {
                                if (webViewerInstance) {
                                    const annotationManager = webViewerInstance.Core.annotationManager;
                                    const annot = annotationManager
                                        .getAnnotationsList()
                                        .filter((_a) => getAnnotationId(_a) === a.id)
                                        .reverse()[0];
                                    if (annot) {
                                        annotationManager.jumpToAnnotation(annot);
                                        annotationManager.selectAnnotation(annot);
                                    }
                                }
                            }}
                        >
                            <AnnotationContentComponent annotation={a} approach={approach} />
                        </ListItem>
                        <Divider />
                    </div>
                ))}
            </List>
        </Paper>
    );
};

const AnnotationContentComponent = (props: { annotation: Annotation; approach: GeneratingAutoAnnotationsApproach }) => {
    const { annotation, approach } = props;
    let content: JSX.Element | null = null;
    switch (approach) {
        case GeneratingAutoAnnotationsApproach.TopDown:
            content = (
                <Box>
                    <Typography sx={{ fontSize: '0.75rem' }}>
                        Group:{' '}
                        <Box component='span' fontWeight='bold'>
                            {annotation.labelGroup}
                        </Box>
                    </Typography>
                    <Typography sx={{ fontSize: '0.75rem' }}>
                        Sub-group:{' '}
                        <Box component='span' fontWeight='bold'>
                            {annotation.labelSubGroup}
                        </Box>
                    </Typography>
                </Box>
            );
            break;
        case GeneratingAutoAnnotationsApproach.BottomUp:
            content = (
                <Box>
                    <Typography sx={{ fontSize: '0.75rem' }}>
                        Category:{' '}
                        <Box component='span' fontWeight='bold'>
                            {annotation.labelGroup.length === 0 ? 'n/a' : annotation.labelGroup}
                        </Box>
                    </Typography>
                </Box>
            );
            break;
    }
    return content;
};

export default AnnotationsListComponent;
