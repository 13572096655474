import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useEffect } from 'react';
import { AnnotationAttributeAnnotationId } from './Attributes';
import { AnnotationColor } from 'Views/Types';
import { AnnotationColors } from 'Views/Consts';
import { Annotation, GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';

const useDrawing = (
    webViewerInstance: WebViewerInstance | null,
    annotations: Array<Annotation>,
    documentLoading: boolean,
    approach: GeneratingAutoAnnotationsApproach | null
) => {
    useEffect(() => {
        if (webViewerInstance && webViewerInstance.Core.documentViewer.getDocument() && !documentLoading && approach) {
            drawAnnotations(webViewerInstance, annotations, approach);
        }
    }, [webViewerInstance, annotations, documentLoading, approach]);
};

export const drawAnnotations = async (instance: WebViewerInstance, annotations: Array<Annotation>, approach: GeneratingAutoAnnotationsApproach) => {
    const { Annotations, annotationManager } = instance.Core;
    const annotationsToRemove: Array<Core.Annotations.Annotation> = [...annotationManager.getAnnotationsList()];
    annotationManager.deleteAnnotations(annotationsToRemove);

    const newAnnotations: Array<Core.Annotations.Annotation> = [];
    annotations.forEach((a) => {
        const parts = a.content.boundingBoxSections.length;
        a.content.boundingBoxSections.forEach((section, idx) => {
            const annot = new Annotations.TextHighlightAnnotation();
            let quads: Array<Core.Math.Quad> = [];
            section.boundingBoxes.forEach((b) => {
                const tL = b.topLeft;
                const dR = b.downRight;

                quads.push(new instance.Core.Math.Quad(tL.x, dR.y, dR.x, dR.y, dR.x, tL.y, tL.x, tL.y));
            });
            annot.Quads = quads;
            annot.PageNumber = section.pageNumber;
            annot.NoResize = true;
            annot.StrokeColor = HighlightColorToAnnotationsColor(instance.Core.Annotations, AnnotationColors.default);
            AnnotationAttributeAnnotationId.set(annot, a.id);
            annot.Author = annotationManager.getCurrentUser();
            let content = getContent(a, approach);
            if (parts > 1) {
                content = `Part: ${idx + 1}/${parts}\n${content}`;
            }
            annot.setContents(content);
            newAnnotations.push(annot);
        });
    });

    annotationManager.addAnnotations(newAnnotations);

    let pagesTmp: Array<number> = [];
    newAnnotations.forEach((a) => {
        const AnnotationPages = [...new Set([a.PageNumber])];
        pagesTmp = [...pagesTmp, ...AnnotationPages];
    });
    const pages = [...new Set(pagesTmp)];

    for (const p of pages) {
        await annotationManager.drawAnnotations({ pageNumber: p, majorRedraw: true });
    }
};

const getContent = (a: Annotation, approach: GeneratingAutoAnnotationsApproach) => {
    let content: string = '';
    switch (approach) {
        case GeneratingAutoAnnotationsApproach.TopDown:
            content = `Group: ${a.labelGroup}\nSub-group: ${a.labelSubGroup}`;
            break;
        case GeneratingAutoAnnotationsApproach.BottomUp:
            content = `Category: ${a.labelGroup.length === 0 ? 'n/a' : a.labelGroup}`;
            break;
    }

    return content;
};

export const HighlightColorToAnnotationsColor = (Annotations: typeof Core.Annotations, c: AnnotationColor) => new Annotations.Color(c.R, c.G, c.B, c.A);

export default useDrawing;
