import BaseApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions } from './Base.ApiClient';

export default class InternalWsApiClient {
    _baseApiClient: BaseApiClient;

    constructor(baseApiClient: BaseApiClient) {
        this._baseApiClient = baseApiClient;
    }

    getDocuments = (approach: GeneratingAutoAnnotationsApproach, requestOptions?: GetRequestOptions<Array<VerifiDocument>>) =>
        this._baseApiClient.getAsync<Array<VerifiDocument>>(`documents/approach/${approach}`, requestOptions);

    getDocument = async (documentId: string, requestOptions: GetRequestOptions<VerifiDocument>) =>
        this._baseApiClient.getAsync<VerifiDocument>(`documents/${documentId}`, requestOptions);

    getDocumentFile = async (documentId: string) => this._baseApiClient.getFileAsync(`documentFiles/${documentId}`, documentId);

    getAnnotations = (documentId: string, requestOptions?: GetRequestOptions<Array<Annotation>>) =>
        this._baseApiClient.getAsync<Array<Annotation>>(`annotations/${documentId}`, requestOptions);

    addAnnotation = (documentId: string, postRequestOptions: PostRequestOptions<AddAnnotationBody>) =>
        this._baseApiClient.postAsync<AddAnnotationBody>(`annotations/${documentId}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Annotation saved.'],
        });

    deleteAnnotation = (documentId: string, annotationId: string, deleteRequestOptions: DeleteRequestOptions) =>
        this._baseApiClient.deleteAsync(`annotations/${documentId}/${annotationId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: ['Annotation removed.'],
        });

    saveXfdfFile = async (documentId: string, postRequestOptions: PostRequestOptions<SaveXfdfFileBody, string>) =>
        this._baseApiClient.postAsync<SaveXfdfFileBody, string>(`documentFiles/${documentId}`, {
            ...postRequestOptions,
            bodyAsDataForm: true,
            toastSuccessMessages: ['Xfdf file saved.'],
        });
}

export type AddAnnotationBody = {
    selectionType: number;
    boundingBoxSections: Array<BoundingBoxSection>;
    labelGroup: string;
    labelSubGroup: string;
};

export type SaveXfdfFileBody = {
    name: string;
    document: File | null;
};

export type VerifiDocument = {
    id: string;
    name: string;
    isDone: boolean;
    annotationsCount: number;
    approach: GeneratingAutoAnnotationsApproach;
};

export type Annotation = {
    id: string;
    labelGroup: string;
    labelSubGroup: string;
    content: {
        boundingBoxSections: Array<BoundingBoxSection>;
    };
};

export type BoundingBoxSection = {
    pageNumber: number;
    pageSize: PageSize;
    boundingBoxes: Array<BoundingBox>;
};

type PageSize = {
    width: number;
    height: number;
};

export type BoundingBox = {
    topLeft: Coordinates;
    downRight: Coordinates;
};

type Coordinates = {
    x: number;
    y: number;
};

export enum GeneratingAutoAnnotationsApproach {
    TopDown = 1,
    BottomUp = 2,
}
