import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { isNewAnnotation } from './Attributes';
import NewAnnotationToolbarComponent from '../Toolbars/NewAnnotationToolbar/NewAnnotationToolbar.Component';
import AnnotationToolbarComponent from '../Toolbars/AnnotationToolbar/AnnotationToolbar.Component';
import { NewAnnotation } from 'Views/Types';
import { AddAnnotationBody, BoundingBox, BoundingBoxSection, GeneratingAutoAnnotationsApproach } from 'Api/InternalWs.ApiClient';
import useTopDown from './Toolbars/Approaches/useTopDown';
import useBottomUp from './Toolbars/Approaches/useBottomUp';

const useToolbars = (
    webViewerInstance: WebViewerInstance | null,
    emotionCache: EmotionCache,
    selectedAnnotationId: string | null,
    addAnnotation: (addAnnotationBody: AddAnnotationBody) => void,
    removeAnnotation: (annotationId: string) => void,
    setExtendAnnotation: () => void,
    approach: GeneratingAutoAnnotationsApproach | null
) => {
    const { groups } = useTopDown(approach);
    const { categories } = useBottomUp(approach);

    const [AnnotationToolbarRef, setAnnotationToolbarRef] = useState<React.RefObject<HTMLDivElement>>();

    useEffect(() => {
        if (webViewerInstance && approach) {
            // Rendering fake toolbar for getting width for right annotationPopup.update below. Without that PdfViewer will display toolbar in incorrect place.
            if (AnnotationToolbarRef?.current) AnnotationToolbarRef?.current.parentElement?.remove();
            const doc = webViewerInstance.UI.iframeWindow.document;
            const container = doc.querySelector(`.content`);
            const newDiv = doc.createElement('div');
            newDiv.style.position = 'absolute';
            newDiv.style.visibility = 'hidden';
            container!.appendChild(newDiv);
            const root = createRoot(newDiv);

            const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
            if (annot) {
                if (isNewAnnotation(annot)) {
                    root.render(
                        <CacheProvider value={emotionCache}>
                            <NewAnnotationToolbarComponent
                                webViewerInstance={webViewerInstance}
                                setAnnotationToolbarRef={setAnnotationToolbarRef}
                                addAnnotation={addAnnotation}
                                getNewAnnotation={() => getNewAnnotation(webViewerInstance)}
                                setExtendAnnotation={setExtendAnnotation}
                                groups={groups}
                                categories={categories}
                                approach={approach}
                            />
                        </CacheProvider>
                    );
                } else {
                    root.render(
                        <CacheProvider value={emotionCache}>
                            <AnnotationToolbarComponent
                                webViewerInstance={webViewerInstance}
                                setAnnotationToolbarRef={setAnnotationToolbarRef}
                                removeAnnotation={removeAnnotation}
                            />
                        </CacheProvider>
                    );
                }
            }
        }
        // eslint-disable-next-line
    }, [webViewerInstance, approach, selectedAnnotationId]);

    useEffect(() => {
        if (webViewerInstance && approach) {
            const clearSelection = () => {
                const annots = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => isNewAnnotation(a));
                webViewerInstance.Core.annotationManager.deleteAnnotations(annots);
            };

            webViewerInstance.UI.annotationPopup.update([
                {
                    type: 'customElement',
                    // It isn't imposible to pass ReactComponent because WebViewer doesn't support hooks in 'customElement'
                    render: () => {
                        const doc = webViewerInstance.UI.iframeWindow.document;
                        const newDiv = doc.createElement('div');
                        const width = AnnotationToolbarRef?.current?.offsetWidth;
                        if (!width || width === 0) return newDiv;
                        newDiv.style.width = `${width}px`;
                        const root = createRoot(newDiv);
                        const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
                        if (annot) {
                            if (isNewAnnotation(annot)) {
                                root.render(
                                    <CacheProvider value={emotionCache}>
                                        <NewAnnotationToolbarComponent
                                            webViewerInstance={webViewerInstance}
                                            setAnnotationToolbarRef={() => {}}
                                            addAnnotation={(a) => {
                                                clearSelection();
                                                addAnnotation(a);
                                            }}
                                            getNewAnnotation={() => getNewAnnotation(webViewerInstance)}
                                            setExtendAnnotation={setExtendAnnotation}
                                            groups={groups}
                                            categories={categories}
                                            approach={approach}
                                        />
                                    </CacheProvider>
                                );
                            } else {
                                root.render(
                                    <CacheProvider value={emotionCache}>
                                        <AnnotationToolbarComponent
                                            webViewerInstance={webViewerInstance}
                                            setAnnotationToolbarRef={() => {}}
                                            removeAnnotation={removeAnnotation}
                                        />
                                    </CacheProvider>
                                );
                            }
                        }

                        return newDiv;
                    },
                },
            ]);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, approach, AnnotationToolbarRef]);
};

const getNewAnnotation = async (instance: WebViewerInstance): Promise<NewAnnotation> => {
    const annots = instance.Core.annotationManager.getAnnotationsList().filter((a) => isNewAnnotation(a));
    return getAnnotationFromAnnots(instance, annots);
};

const getAnnotationFromAnnots = async (instance: WebViewerInstance, annots: Array<Core.Annotations.Annotation>): Promise<NewAnnotation> => {
    const { documentViewer } = instance.Core;
    let boundingBoxSections: Array<BoundingBoxSection> = [];
    let statement = '';

    if (annots.length > 0) {
        for (const annot of annots) {
            const pageNumber = annot.getPageNumber();
            const pageWidth = documentViewer.getPageWidth(pageNumber);
            const pageHeight = documentViewer.getPageHeight(pageNumber);
            statement += (await getStatement(instance, pageNumber, annot)) + ' ';
            boundingBoxSections.push({
                pageNumber,
                pageSize: {
                    height: pageHeight,
                    width: pageWidth,
                },
                boundingBoxes: getBoundingBoxes(annot),
            });
        }
    }

    return {
        statement,
        boundingBoxSections,
    };
};

const getBoundingBoxes = (annot: Core.Annotations.Annotation) => {
    let boundingBoxes: Array<BoundingBox> = [];

    const quads: Array<Core.Math.Quad> = (annot as Core.Annotations.TextHighlightAnnotation).Quads;
    for (let i = 0; i < quads.length; i++) {
        const quad = quads[i];
        boundingBoxes.push({
            topLeft: {
                x: quad.x4,
                y: quad.y4,
            },
            downRight: {
                x: quad.x2,
                y: quad.y2,
            },
        });
    }

    return boundingBoxes;
};

const getStatement = async (webViewerInstance: WebViewerInstance, pageNumber: number, annot: Core.Annotations.Annotation) => {
    let statement: string = '';
    const quads: Array<Core.Math.Quad> = (annot as Core.Annotations.TextHighlightAnnotation).Quads;
    for (let i = 0; i < quads.length; i++) {
        const rect = new webViewerInstance.Core.Math.Rect(quads[i].x4, quads[i].y4, quads[i].x2, quads[i].y2);
        statement += (await getSelectedTextFromRectangle(webViewerInstance, pageNumber, rect)) + ' ';
    }

    return statement;
};

const getSelectedTextFromRectangle = async (instance: WebViewerInstance, pageNumber: number, rect: Core.Math.Rect) => {
    const txt = await instance.Core.PDFNet.TextExtractor.create();
    const doc = await instance.Core.documentViewer.getDocument().getPDFDoc();
    const page = await doc.getPage(pageNumber);
    const pB = await page.getCropBox();
    const rectangle = new instance.Core.PDFNet.Rect(rect.x1, pB.y2 - rect.y1, rect.x2, pB.y2 - rect.y2);

    txt.begin(page, rectangle);
    const text = await txt.getAsText();

    return text;
};

export default useToolbars;
